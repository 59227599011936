@font-face {
  font-family: 'rock';
  src: url(./fonts/rockledgewutikerned-webfont.woff) format('woff');
}

.App {
  text-align: center;
  
}

body {
  overflow: hidden;
  background-color: #eee;
  -webkit-overflow-scrolling: touch;
  
}

.splashImage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  filter: grayscale(1);
  background-size: 100%;
  opacity: 0.7;
}

a:visited {
  color: blue;
}

a:hover {
  color: #444;
}

.fade-in-section {
  opacity: 0;
  transform: translateX(-20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.App-nav {
  background-color:#eee;
  height:100vh;
  width:50vw;
  color: blue;
  border-right:solid thick blue;
  position: absolute;
  top: 0;
  left: 0;
  /* transition: margin .5s, visibility 0s linear 300ms; */
  margin: 0 0 0 -50vw;
  z-index: 20;
  display: none;
}
.wrapper.is-nav-open{
  margin-left: 0;
  z-index: 100;
}
.btn.rotate-nav{
  transform:rotate(45deg);
  opacity: 0;
  visibility: hidden;
}
.logo.rotate-nav{
  opacity: 0;
  visibility: hidden;
}
.logo:hover, .btn:hover {
  cursor: pointer;
  color: #444;
}
/* .active-page{
  /* background-color: blue !important; 
  
} */
.active-page a{
  color:white!important;
}
li:has(li > a.active-page){
  background:blue;
}
.App-info{
  background-color: blue;
  height:100vh;
  width:50vw;
  position: absolute;
  top: 0px;
  right: -50vw;
  /* transition: right .5s, display .3s; */
  border-left: solid thick blue;
  z-index: 1;
  display: none;
}
.wrapper_i.is-info-open{
  right: 0;
  z-index:100;
  display: block;
}
.info-btn {
  position: absolute;
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 65px;
  right:2%;
  top:0;
  /* font-size: 55px; */
  font-size:1em;
  font-weight: bold;
  color: blue;
  transition: transform .5s, opacity .5s, visibility 0s linear 300ms;
  transform: rotate(0);
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
.btn_i.rotate-info {
  transform:rotate(-45deg);
  opacity: 0;
  visibility: hidden;
}
.infoLogo.rotate-info {
  opacity: 0;
  visibility: hidden;
}
.txt_content {
  padding: 2vw;
  color: white;
  font-size: 2em;
  white-space: pre-line;
}
.txt_content p:first-child{
  font-family: 'rock';
  text-transform: uppercase;
}
/* .container.wrapper_i:hover{
  
} */
.container {
padding: 0;
height: 100vh;
overflow-y: auto;
scrollbar-width: none;
}
.container::-webkit-scrollbar{
  display: none;
}
.jLeft {
  text-align: left;
}
.nav-btn {
  width: 65px;
  height: 65px;
  /* background-color: blue; */
  /* margin-left: 50vw; */
  text-align: center;
  line-height: 65px;
  /* font-size: 55px; */
  font-size: 1em;
  font-weight: bold;
  color: blue;
  transition: opacity .5s;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 2%;
  z-index: 1;
}
.logo {
  position: absolute;
    /* margin-left: calc( 50vw + 65px ); */
    top: 4%;
    font-size: 1em;
    width: 65px;
    /* padding-left: 2vw; */
    line-height: 70px;
    font-weight: bold;
    text-align: center;
    transition: opacity .5s;
    color: blue;
    opacity: 1;
    left: 2%;
  
}
.infoLogo {
  position: absolute;
  /* margin-left: calc( 50vw + 65px ); */
  top: 4%;
  font-size: 1em;
  width: 65px;
  /* padding-left: 2vw; */
  line-height: 70px;
  font-weight: bold;
  text-align: center;
  right: 2%;
  z-index: -10;
  color: blue;
  transition: opacity .5s, visibility 0s linear 300ms;
  opacity: 1;
}

.info-btn:hover, .infoLogo:hover {
  cursor: pointer;
  color: #444;
}
.nav-menu {
  list-style: none;
  padding: 0;
  margin:0;
  float: left;
  width: 100%;
  padding-bottom: 3vw;
}
.nav-items {
  margin-top: 3vw;
  background-color: white;
  /* border-bottom: solid thick blue; */
  transition: box-shadow .5s, left .2s, filter .5s;
  /* transition: margin-left .5s; */
  position: relative;
  left: 0;
  font-size: 2em;
  box-shadow: 0px 0px 0px 0px #eeeeee;
  filter: grayscale(100%);
}
.nav-items:hover {
  background-color: blue;
  box-shadow: 10px 10px 15px 0px rgba(170,170,170,0.82);
  left: -2px;
  filter: grayscale(0%);
}
.nav-items:hover a {
  color: white;
}
.nav-items a {
  color: blue;
  text-decoration: none;
  font-weight: light;
  text-transform: uppercase;
}
.nav-items a img {
  width: 100%;
  display: inline;
  vertical-align: bottom;
  position: relative;
}
.player {
  position: absolute;
  bottom: 1vh;
  width: 100vw;
  z-index: 10;
}


.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -3vw; /*gutter size offset */
  width: auto;
  padding: 0vh 3vw;
}
.masonry-grid_column {
  padding-left: 3vw; /* gutter size */
  background-clip: padding-box;
}

.splash {
  width: 100vw;
  height: 100vh;
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  background-size: 20% ;
  position: absolute;
  top: 0px;
}
.splash:hover {
  cursor: pointer;
}
.splash-title {
  font-family: 'rock';
  width: 100%;
  position: absolute;
  /* bottom: 1em; */
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
  color: blue;
  /* line-height: 160px; */
  /* padding-top: calc( 2vw + 1em ); */
}

.menu-frame {
  position: relative;
  top: 0;
  left:0;
  width: 60vw;
  text-align: center;
  margin: 0 auto;
  z-index: 10;
}
.menu {
  list-style: none;
  display: inline;
  padding: 0px;
}
.menu-item {
  display: inline;
  font-size: 1rem;
  line-height: 70px;
  font-weight: bold;
  padding: 0 10px 0 10px;
}
.menu-item a {
  text-decoration: none;
}

.project-container {
  position: absolute;
  top: 0;
  left: 0;
  width:100vw;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.project-container::-webkit-scrollbar {
    display: none;
}
.project-img-list{
  padding: 0;
  list-style: none;
  margin: 0;
  /* height: 100%; */
}
.project-items{
  position: relative;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: 50% auto;
  border-bottom: dotted thin blue;
}
.project-img-list img{
  width: 75%;
  max-height: 70vh;
  object-fit: contain;
}
.project-img-container{
  position: relative;
  margin: 0 auto;
  width:70%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* float: left; */
}
.project-vid-container{
  position: relative;
  margin: 0 auto;
  width:70%;
  height: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* float: left; */
}
.project-captions{
  position: absolute;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: blue;
  text-align: left;
  width: 20%;
  left: 2%;
  /* float: left; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  white-space: pre-line; 
}
.about-wrapper{
  position: absolute;
  height:100vh;
  width: 100vw;
  top:0;
}
.about-container{
  position: relative;
  height: 100vh;
  width: 70vw;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;  /* IE and Edge */
  /* padding-top: 70px; */
  margin: 0 auto;
}
.about-container::-webkit-scrollbar {
  display: none;
}
.about-txt {
  white-space: pre-line;
}
.about-container div.section {
  width: 30%;
  float: left;
  text-align: left;
  line-height: 1.5em;
  padding-bottom: 100px;
  margin-left:1.5%;
  margin-right: 1.5%;
}
div.section ul{
  list-style: none;
  padding-left: 0;
}
p.section-title{
  border-top: solid thin black;
  padding-top: 40px;
  font-weight: bold;
  margin-top: 70px;
}
p.yr {
  font-style: italic;
}
li.Edept {
  font-weight: bold;
}
li.institution {
  font-weight: bold;
}
@media (orientation: portrait) {
  .App-nav, .App-info {
    width: 100vw;
  }
  .App-nav {
    margin-left: -100vw;
  }

  .App-info {
    right: -100vw;
  }
  .logo, .infoLogo {
    
    /* margin-left: calc( 100vw + 65px ); */
  }
  .infoLogo {
    /* width: 100vw; */
  }
  .nav-btn {
    /* margin-left: 100vw; */
  }
  .about-container{
    width:90vw;
  }
  .project-vid-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 42%;
    top: 50%;
    transform: translateY(-50%);
    float: left;
  }
  .project-img-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    /* float: left; */
  }
  .project-captions {
    position: absolute;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: blue;
    text-align: left;
    width: 75%;
    left: 12.5%;
    /* float: left; */
  
    transform: none;
    top: 80%;
    white-space: pre-line;
    /* margin: 0 auto;*/
  }
}

@media only screen and (max-width: 570px) {
  .infoLogo, .logo {
    visibility: hidden;
    font-size: 0.7em;
  }
  .logo {
    visibility: hidden;
  }
  .menu-item {
    font-size: 0.7em;
  }
  .info-btn, .nav-btn {
    font-size: 0.7em;
    top:2px;
  }
  .txt_content {
    font-size: 1.5em;
  }
  .project-captions {
    font-size: 0.5em;
  }
  div.about-container > .section {
    width: 100%;
    margin-left: 0;
  }

}

@media only screen and (max-width: 1030px) and (orientation: landscape){
  div.about-container{
    width: 98vw!important;
  }
}

@media only screen and (max-width: 1030px){
  .project-captions{
    /* font-size: 0.5em; */
  }
  div.about-container{
    width: 90vw;
  }
}

.hideEl {
  display: none;
}
.isOpenFromRight {
  /* right: 0; */
}
.isOpenFromLeft {
  /* right: 0; */
}